$('#flash-overlay-modal').modal();

$('#exampleModal').on('show.bs.modal', function (event) {
    var link = $(event.relatedTarget)

    var data = link.data('type')
    var info = link.data('info');
    var extra = link.data('office-hours');

    switch(data) {
        case 'email':
            info = 'E-Mail Address: <a href="mailto:' + info + '">' + info + '</a>';
            break;
        case 'website':
            info = 'Website: <a href="' + info + '">' + info + '</a>';
            break;
        case 'phone':
            info = '<div>Phone: ' + info + '</div>';
            if (extra) {
                info += '<div style="margin-top:10px;margin-bottom:15px;">Office hours:<div>' + extra + '</div></div>';
                info += '<div>Use email or text messaging after hours.</div>';
            }
            break;
    }

    $('#info').html(info)
});

//$('#descriptionModal').on('show.')